
<template>
  <!--begin::Basic info-->
  <div class="card">
    <!--begin::Card header-->
    <div class="card-header border-0 pt-3">
      <!--begin::Card title-->
      <div class="card-title m-0">
        <h3 class="fw-bolder m-0">
          {{ $t(PAGE_TITLE_PATH.ANIMAL_CREATE) }}
        </h3>
      </div>
      <!--end::Card title-->
    </div>
    <!--begin::Card header-->

    <!--begin::Content-->
    <div id="kt_account_profile_details" class="collapse show">
      <!--begin::Form-->
      <Form
        id="kt_account_profile_details_form"
        class="form"
        novalidate="novalidate"
        @submit="handleSubmit"
        :validation-schema="createAnimal"
      >
        <!--begin::Card body-->
        <div class="card-body border-top p-9 ps-14">
          <div class="row mb-6">
            <div class="col-md-6">
              <div class="row mb-5">
                <label class="col-lg-4 col-form-label fw-bold fs-6">{{
                  $t("menuPages.createAnimal.photo")
                }}</label>

                <div class="col-lg-8">
                  <div
                    class="image-input image-input-outline"
                    data-kt-image-input="true"
                    style="background-image: url(media/avatars/blank.png)"
                  >
                    <div
                      class="image-input-wrapper w-125px h-125px"
                      :style="`background-image: url(${animalModel.profilePhotoUrl})`"
                    ></div>
                    <label
                      class="
                        btn btn-icon btn-circle btn-active-color-primary
                        w-25px
                        h-25px
                        bg-white
                        shadow
                      "
                      data-kt-image-input-action="change"
                      data-bs-toggle="tooltip"
                      title="Change avatar"
                    >
                      <i class="bi bi-pencil-fill fs-7"></i>
                      <input
                        type="file"
                        name="avatar"
                        accept=".png, .jpg, .jpeg"
                        @change="onFileChange($event)"
                      />

                      <Field type="hidden" name="avatar_remove" />
                    </label>

                    <span
                      class="
                        btn btn-icon btn-circle btn-active-color-primary
                        w-25px
                        h-25px
                        bg-white
                        shadow
                      "
                      data-kt-image-input-action="remove"
                      data-bs-toggle="tooltip"
                      @click="removeImage()"
                      title="Remove avatar"
                    >
                      <i class="bi bi-x fs-2"></i>
                    </span>
                  </div>

                  <div class="form-text">
                    {{ $t("menuPages.createAnimal.photoDesc") }}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="row mb-2">
            <div class="col-md-6">
              <div class="row mb-5">
                <!--begin::Label-->
                <label class="col-lg-4 col-form-label fw-bold fs-6">
                  <span class="required">{{
                    $t("menuPages.createAnimal.earringNumber")
                  }}</span>
                </label>
                <!--end::Label-->

                <!--begin::Select-->
                <div class="col-lg-6 fv-row">
                  <Field
                    v-model="animalModel.earTag"
                    name="earTag"
                    class="form-control form-control-lg form-control-solid"
                    :placeholder="$t('menuPages.createAnimal.earringNumber')"
                  />
                  <div class="fv-plugins-message-container">
                    <div class="fv-help-block">
                      <ErrorMessage name="earTag" />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-md-6">
              <div class="row mb-5">
                <!--begin::Label-->
                <label class="col-lg-4 col-form-label fw-bold fs-6">
                  <span class="required">{{
                    $t("menuPages.createAnimal.race")
                  }}</span>
                </label>
                <!--end::Label-->

                <!--begin::Col-->
                <div class="col-lg-6 fv-row">
                  <Field
                    name="breed"
                    data-control="select1"
                    data-hide-search="true"
                    data-placeholder='{{ $t("menuPages.createAnimal.selectRace") }}'
                    class="form-select form-select-solid"
                    as="select"
                    v-model="animalModel.animalBreedId"
                  >
                  <option value="0" selected>
                      {{ $t("menuPages.createAnimal.selectRace") }}
                    </option>
                    <option
                      v-for="breed in breedList"
                      :key="breed.breed.id"
                      :value="parseInt(breed.breed.id)"
                    >
                      {{ breed.breed.name }}
                    </option>
                  </Field>
                  <div class="fv-plugins-message-container">
                    <div class="fv-help-block">
                      <ErrorMessage name="breed" />
                    </div>
                  </div>
                </div>
                <!--end::Col-->
              </div>
            </div>
          </div>

          <div class="row mb-2">
            <div class="col-md-6">
              <div class="row mb-5">
                <!--begin::Label-->
                <label class="col-lg-4 col-form-label fw-bold fs-6">
                  <span class="required">{{
                    $t("menuPages.createAnimal.nickname")
                  }}</span>
                </label>
                <!--end::Label-->

                <!--begin::Col-->
                <div class="col-lg-6 fv-row">
                  <Field
                    v-model="animalModel.name"
                    type="text"
                    name="name"
                    class="form-control form-control-lg form-control-solid"
                    :placeholder="$t('menuPages.createAnimal.nickname')"
                  />
                  <div class="fv-plugins-message-container">
                    <div class="fv-help-block">
                      <ErrorMessage name="name" />
                    </div>
                  </div>
                </div>
                <!--end::Col-->
              </div>
            </div>
            <div class="col-md-6">
              <div class="row mb-5">
                <!--begin::Label-->
                <label class="col-lg-4 col-form-label fw-bold fs-6">
                  <span>{{ $t("menuPages.createAnimal.birthDate") }}</span>
                </label>
                <!--end::Label-->

                <!--begin::Col-->
                <div class="col-lg-6 fv-row">
                  <Field
                    v-model="birthDate"
                    type="date"
                    :max="new Date().toISOString().split('T')[0]"
                    name="birthDate"
                    class="form-control form-control-lg form-control-solid"
                    :placeholder="$t('menuPages.createAnimal.birthDate')"
                  />
                </div>
                <div class="fv-plugins-message-container">
                    <div class="fv-help-block">
                      <ErrorMessage name="date" />
                    </div>
                  </div>
                <!--end::Col-->
              </div>
            </div>
          </div>

          <div class="row mb-2">
            <div class="col-md-6">
              <div class="row mb-5">
                <!--begin::Label-->
                <label class="col-lg-4 col-form-label fw-bold fs-6">
                  <span>{{ $t("menuPages.createAnimal.color") }}</span>
                </label>
                <!--end::Label-->

                <!--begin::Col-->
                <div class="col-lg-6 fv-row">
                  <Field
                    v-model="animalModel.color"
                    type="text"
                    name="color"
                    class="form-control form-control-lg form-control-solid"
                    :placeholder="$t('menuPages.createAnimal.color')"
                  />
                </div>
                <!--end::Col-->
              </div>
            </div>

            <div class="col-md-6">
              <div class="row mb-5">
                <!--begin::Label-->
                <label class="col-lg-4 col-form-label fw-bold fs-6">
                  <span class="required">{{
                    $t("menuPages.createAnimal.gender")
                  }}</span>
                </label>
                <!--end::Label-->

                <!--begin::Col-->
                <div class="col-lg-6 fv-row">
                  <Field
                    v-model="animalModel.gender"
                    name="gender"
                    data-control="select2"
                    data-hide-search="true"
                    data-placeholder='{{ $t("menuPages.createAnimal.selectGender") }}'
                    class="form-select form-select-solid"
                    as="select"
                  >
                  <option value="0" selected>
                      {{ $t("menuPages.createAnimal.selectGender") }}
                    </option>
                    <option
                      v-for="gender in Object.values(GenderEnum).filter(
                        (value) => typeof value != 'number'
                      )"
                      :key="gender"
                      :value="gender"
                    >
                      {{
                        gender.toString().charAt(0).toUpperCase() +
                        gender.toString().slice(1).toLowerCase()
                      }}
                    </option>
                  </Field>
                  <div class="fv-plugins-message-container">
                    <div class="fv-help-block">
                      <ErrorMessage name="gender" />
                    </div>
                  </div>
                </div>
                <!--end::Col-->
              </div>
            </div>
          </div>

          <div class="row mb-2">
            <div class="col-md-6">
              <div class="row mb-5">
                <!--begin::Label-->
                <label class="col-lg-4 col-form-label fw-bold fs-6">
                  <span>{{ $t("menuPages.createAnimal.lastWeight") }}</span>
                  <!--
                <i
                  class="fas fa-exclamation-circle ms-1 fs-7"
                  data-bs-toggle="tooltip"
                  title="Phone number must be active"
                ></i>
  -->
                </label>
                <!--end::Label-->

                <!--begin::Col-->
                <div class="col-lg-6 fv-row">
                  <div class="input-group">
                    <Field
                      v-model="animalModel.liveWeight"
                      type="number"
                      min="50"
                    max="5000"
                      name="liveWeight"
                      class="form-control form-control-lg form-control-solid"
                      :placeholder="$t('menuPages.createAnimal.lastWeight')"
                    />
                    <div
                      class="form-control form-control-lg form-control-solid"
                    >
                      Kilo
                    </div>
                  </div>
                  <div class="fv-plugins-message-container">
                    <div class="fv-help-block">
                      <ErrorMessage name="liveWeight" />
                    </div>
                  </div>
                </div>
                <!--end::Col-->
              </div>
            </div>
            <div class="col-md-6">
              <div class="row mb-5">
                <!--begin::Label-->
                <label class="col-lg-4 col-form-label fw-bold fs-6">
                  <span>{{ $t("menuPages.createAnimal.price") }}</span>
                  <!--
                <i
                  class="fas fa-exclamation-circle ms-1 fs-7"
                  data-bs-toggle="tooltip"
                  title="Phone number must be active"
                ></i>
  -->
                </label>
                <!--end::Label-->

                <!--begin::Col-->
                <div class="col-lg-6 fv-row">
                  <div class="input-group">
                    <Field
                      v-model="animalModel.acquisitionPrice"
                      type="number"
                      min="1"
                      name="acquisitionPrice"
                      class="form-control form-control-lg form-control-solid"
                      :placeholder="$t('menuPages.createAnimal.price')"
                    />
                    <div
                      class="form-control form-control-lg form-control-solid"
                    >
                      ₺
                    </div>
                  </div>
                  <div class="fv-plugins-message-container">
                    <div class="fv-help-block">
                      <ErrorMessage name="acquisitionPrice" />
                    </div>
                  </div>
                </div>
                <!--end::Col-->
              </div>
            </div>
          </div>

          <div class="row mb-2">
            <div class="col-md-6">
              <div class="row mb-5">
                <!--begin::Label-->
                <label class="col-lg-4 col-form-label fw-bold fs-6">
                  <span>{{
                    $t("menuPages.createAnimal.motherEarNumber")
                  }}</span>
                </label>
                <!--end::Label-->

                <!--begin::Select-->
                <div class="col-lg-6 fv-row">
                  <Field
                    v-model="animalModel.motherEarTag"
                    type="string"
                    name="motherEarTag"
                    class="form-control form-control-lg form-control-solid"
                    :placeholder="$t('menuPages.createAnimal.motherEarNumber')"
                  />
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <div class="row mb-5">
                <!--begin::Label-->
                <label class="col-lg-4 col-form-label fw-bold fs-6">
                  <span>{{
                    $t("menuPages.createAnimal.fatherEarNumber")
                  }}</span>
                  <!--
                <i
                  class="fas fa-exclamation-circle ms-1 fs-7"
                  data-bs-toggle="tooltip"
                  title="Phone number must be active"
                ></i>
  -->
                </label>
                <!--end::Label-->

                <!--begin::Col-->
                <div class="col-lg-6 fv-row">
                  <Field
                    v-model="animalModel.fatherEarTag"
                    type="text"
                    name="fatherEarTag"
                    class="form-control form-control-lg form-control-solid"
                    :placeholder="$t('menuPages.createAnimal.fatherEarNumber')"
                  />
                </div>
                <!--end::Col-->
              </div>
            </div>
          </div>

          <div class="row mb-2" style="border-top: 1px solid #eff2f5">
            <h4 class="fw-bolder mt-6">
              {{ $t("menuPages.createAnimal.companyInfo") }}
            </h4>
            <div class="col-md-6 mt-4">
              <div class="row mb-5">
                <!--begin::Label-->
                <label class="col-lg-4 col-form-label fw-bold fs-6">
                  <span>{{
                    $t("menuPages.createAnimal.companyFirstDate")
                  }}</span>
                </label>
                <!--end::Label-->

                <!--begin::Col-->
                <div class="col-lg-6 fv-row">
                  <Field
                    v-model="acquisitionDate"
                    type="date"
                    :max="new Date().toISOString().split('T')[0]"
                    name="acquisitionDate"
                    class="form-control form-control-lg form-control-solid"
                    :placeholder="$t('menuPages.createAnimal.companyFirstDate')"
                  />
                  <div class="fv-plugins-message-container">
                    <div class="fv-help-block">
                      <ErrorMessage name="date" />
                    </div>
                  </div>
                </div>
                <!--end::Col-->
              </div>
            </div>
            <div class="col-md-6 mt-4">
              <div class="row mb-5">
                <!--begin::Label-->
                <label class="col-lg-4 col-form-label fw-bold fs-6">
                  <span class="required">{{
                    $t("menuPages.createAnimal.companyEnterDesc")
                  }}</span>
                  <!--
                <i
                  class="fas fa-exclamation-circle ms-1 fs-7"
                  data-bs-toggle="tooltip"
                  title="Phone number must be active"
                ></i>
  -->
                </label>
                <!--end::Label-->

                <!--begin::Col-->
                <div class="col-lg-6 fv-row">
                  <Field
                    v-model="animalModel.acquisitionReason"
                    name="acquisitionReason"
                    data-control="select3"
                    data-hide-search="true"
                    data-placeholder='{{ $t("menuPages.createAnimal.companyEnterDesc") }}'
                    class="form-select form-select-solid"
                    as="select"
                  >
                    <option value="0">
                      {{ $t("menuPages.createAnimal.companyEnterDesc") }}
                    </option>
                    <option
                      v-for="acquisitionReason in Object.values(
                        AcquisitionReasonEnum
                      ).filter((value) => typeof value != 'number')"
                      :key="acquisitionReason"
                      :value="acquisitionReason"
                    >
                      {{
                        acquisitionReason.toString().charAt(0).toUpperCase() +
                        acquisitionReason.toString().slice(1).toLowerCase()
                      }}
                    </option>
                  </Field>
                  <div class="fv-plugins-message-container">
                    <div class="fv-help-block">
                      <ErrorMessage name="acquisitionReason" />
                    </div>
                  </div>
                </div>
                <!--end::Col-->
              </div>
            </div>
          </div>

          <div class="row mb-2 pt-3" style="border-top: 1px solid #eff2f5">
            <div class="col-md-6 mt-4">
              <div class="row mb-5">
                <!--begin::Label-->
                <label class="col-lg-4 col-form-label fw-bold fs-6">
                  <span class="required">{{
                    $t("menuPages.createAnimal.barnyard")
                  }}</span>
                  <!--
                <i
                  class="fas fa-exclamation-circle ms-1 fs-7"
                  data-bs-toggle="tooltip"
                  title="Phone number must be active"
                ></i>
  -->
                </label>
                <!--end::Label-->

                <!--begin::Col-->
                <div class="col-lg-6 fv-row">
                  <Field
                    name="barnyard"
                    data-control="select4"
                    data-hide-search="true"
                    data-placeholder='{{ $t("menuPages.createAnimal.selectBarnyard") }}'
                    class="form-select form-select-solid"
                    as="select"
                    v-model="animalModel.barnyard"
                  >
                    <option value="0" selected>
                      {{ $t("menuPages.createAnimal.selectBarnyard") }}
                    </option>
                    <option
                      v-for="barnyard in barnyardList"
                      :key="barnyard.barnyard.id"
                      :value="parseInt(barnyard.barnyard.id)"
                    >
                      {{ barnyard.barnyard.name }}
                    </option>
                  </Field>
                  <div class="fv-plugins-message-container">
                    <div class="fv-help-block">
                      <ErrorMessage name="barnyard" />
                    </div>
                  </div>
                </div>
                <!--end::Col-->
              </div>
            </div>
          </div>

          <div class="row mb-2">
            <div class="col-md-12 mt-4">
              <div class="row mb-5">
                <label class="col-lg-2 col-form-label fw-bold fs-6">
                  <span>{{
                    $t("menuPages.createAnimal.tags")
                  }}</span>
                  <i
                    class="fas fa-exclamation-circle ms-2 fs-7"
                    data-bs-toggle="tooltip"
                    :title="$t('menuPages.createAnimal.tagsValid')"
                  ></i>
                </label>
                <!--end::Label-->
                <div class="col-lg-9 fv-row">
                  <el-select
                    class="col-lg-5"
                    v-model="animalModel.animalTags"
                    multiple
                    placeholder="Seçiniz..."
                    @change="checkSelectedOptions"
                  >
                    <el-option
                      v-for="animalTag in animalTagList.filter(
                        (value) => typeof value != 'number'
                      )"
                      :key="animalTag.animalTag"
                      :label="animalTag.animalTag.name"
                      :value="animalTag.animalTag.id"
                    >
                    </el-option>
                  </el-select>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!--end::Card body-->
        <!--begin::Actions-->
        <div class="card-footer d-flex justify-content-end py-6 px-9">
          <!--begin::Submit button-->
          <button
            type="submit"
            id="kt_account_profile_details_submit"
            ref="submitButton"
            class="btn btn-primary"
          >
            <span class="indicator-label">
              {{ $t("common.button.save") }}
            </span>
            <span class="indicator-progress">
              {{ $t("pleaseWait") }}
              <span
                class="spinner-border spinner-border-sm align-middle ms-2"
              ></span>
            </span>
          </button>
          <!--end::Submit button-->
        </div>
        <!--end::Actions-->
      </Form>
      <!--end::Form-->
    </div>
    <!--end::Content-->
  </div>
  <!--end::Basic info-->
</template>

<style lang="scss">
.aside-menu .menu .menu-sub .menu-item a a.menu-link {
  padding-left: calc(0.75rem + 25px);
  cursor: pointer;
  display: flex;
  align-items: center;
  flex: 0 0 100%;
  transition: none;
  outline: none !important;
}

.aside-menu .menu .menu-sub .menu-sub .menu-item a a.menu-link {
  padding-left: calc(1.5rem + 25px);
  cursor: pointer;
  display: flex;
  align-items: center;
  flex: 0 0 100%;
  transition: none;
  outline: none !important;
}
</style>

<script lang="ts">
import { defineComponent, onMounted, ref } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import { useI18n } from "vue-i18n";
import {
  COMPONENT_NAME,
  PAGE_TITLE_PATH,
  SWAL_MESSAGES,
  VALIDATOR_ERROR,
} from "@/domain/Constant";
import * as Yup from "yup";
import { setCurrentPageTitle } from "@/presentation/helper/Breadcrumb";
import store from "@/presentation/store";
import SwalNotification from "@/presentation/plugins/SwalNotification";
import { GenderEnum } from "@/domain/enum/Gender";
import { AcquisitionReasonEnum } from "@/domain/enum/AcquisitionReason";
import { AnimalStatusEnum } from "@/domain/enum/AnimalStatus";
import { AnimalDetailModel } from "@/domain/animal/animalDetail/model/AnimalDetailModel";
import { BreedListModel } from "@/domain/breed/breedList/model/BreedListModel";
import { AnimalTagListModel } from "@/domain/animalTag/animalTagList/model/AnimalTagListModel";
import { useRouter } from "vue-router";
import { BarnyardListModel } from "@/domain/barnyard/barnyardList/model/BarnyardListModel";


export default defineComponent({
  name: COMPONENT_NAME.CREATE_ANIMAL,
  components: { Field, Form, ErrorMessage },
  methods: {
    checkSelectedOptions(selectedOptions) {
      if (selectedOptions && selectedOptions.length > 3) {
        if (this.animalModel.animalTags)
          this.animalModel.animalTags = this.animalModel.animalTags.slice(0, 3);
      }
    },
  },
  setup() {
    const { t, te } = useI18n();
    const swalNotification = new SwalNotification();
    const scrollElRef = ref<null | HTMLElement>(null);
      const router = useRouter();
    const submitButton = ref<HTMLElement | null>(null);
    const animalTagList = ref<AnimalTagListModel[]>([]);

    const animalTagController =
      store.state.ControllersModule.animalTagController;
    const animalController = store.state.ControllersModule.animalController;
    const breedController = store.state.ControllersModule.breedController;
    const barnyardController = store.state.ControllersModule.barnyardController;
    const barnyardList = ref<any[]>([]);
    const breedList = ref<any[]>([]);
    const acquisitionDate = ref(new Date().toISOString().split("T")[0]);
    const birthDate = ref(new Date().toISOString().split("T")[0]);

    const createAnimal = Yup.object().shape({
      breed: Yup.string()
        .typeError(t(VALIDATOR_ERROR.typeError))
        .test(
          "isNotZero",
          t(VALIDATOR_ERROR.required),
          (value) => value !== "0"
        )
        .label("breed"),
      earTag: Yup.string()
        .typeError(t(VALIDATOR_ERROR.typeError))
        .required(t(VALIDATOR_ERROR.required))
        .label("earTag"),
      name: Yup.string()
        .typeError(t(VALIDATOR_ERROR.typeError))
        .required(t(VALIDATOR_ERROR.required))
        .label("name"),
      gender: Yup.string()
        .typeError(t(VALIDATOR_ERROR.typeError))
        .test(
          "isNotZero",
          t(VALIDATOR_ERROR.required),
          (value) => value !== "0"
        )
        .label("gender"),
      acquisitionReason: Yup.string()
        .typeError(t(VALIDATOR_ERROR.typeError))
        .test(
          "isNotZero",
          t(VALIDATOR_ERROR.required),
          (value) => value !== "0"
        )
        .label("acquisitionReason"),
      barnyard: Yup.string()
        .typeError(t(VALIDATOR_ERROR.typeError))
        .test(
          "isNotZero",
          t(VALIDATOR_ERROR.required),
          (value) => value !== "0"
        )
        .label("barnyard"),
      liveWeight: Yup.number()
        .typeError(t(VALIDATOR_ERROR.typeError))
        .min(0, t(VALIDATOR_ERROR.zeroValue))
        .label("liveWeight"),
      acquisitionPrice: Yup.number()
        .typeError(t(VALIDATOR_ERROR.typeError))
        .min(0, t(VALIDATOR_ERROR.zeroValue))
        .label("acquisitionPrice"),
    });

    const barnyardId = ref(Number(store.getters.selectedBarnyardId));

    const animalModel = ref<AnimalDetailModel>({
      id: 0,
      earTag: "",
      motherEarTag: "",
      fatherEarTag: "",
      name: "",
      animalBreedId: 0,
      barnyard: barnyardId.value,
      gender: "0",
      acquisitionDate: new Date(),
      acquisitionPrice: 0,
      acquisitionReason: "0",
      liveWeight: 0,
      status: "0",
      profilePhotoUrl: "media/animal/animal.jpg",
      birthDate: new Date(),
    });

    const currentPage = ref(1);
    const pageSize = ref(10);
    const totalItems = ref(0);
    const pageCount = ref(1);

    onMounted(() => {
      setCurrentPageTitle(t(PAGE_TITLE_PATH.ANIMAL_CREATE));
      if (scrollElRef.value) {
        scrollElRef.value.scrollTop = 0;
      }

      getBarnyardList();

      if (store.getters.selectedBarnyardId) {
        animalModel.value.barnyard = store.getters.selectedBarnyardId;
      }

      getBreeds();
      getAnimalTags();
    });

    const getBarnyardList = () => {

      const barnyardListModel: BarnyardListModel = {
        barnyard: {},
        page: 1,
        pageSize: 50,
      };

      barnyardController
        .barnyardList(barnyardListModel)
        .then((response) => {
          if (response.isSuccess) {
            response.getValue().forEach((barnyard) => {
              totalItems.value = barnyard.pagination.total;
              currentPage.value = barnyard.pagination.page;
              pageCount.value = barnyard.pagination.pageCount;
              barnyardList.value.push(barnyard);
            });
          } else {
            swalNotification.error(
              response.error.cause.cause +
                t(SWAL_MESSAGES.ERROR_CODE) +
                response.error.code +
                response.error.cause.code,
              t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT)
            );
          }
        })
        .catch((e) => {
          swalNotification.error(e, t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT));
        });
    };

    const getBreeds = () => {
      const breedListModel: BreedListModel = {
        breed: {
          id: 0,
        },
        page: 1,
        pageSize: 10,
      };

      breedController
        .breedList(breedListModel)
        .then((response) => {
          if (response.isSuccess) {
            response.getValue().forEach((breed) => {
              breedList.value.push(breed);
            });
          } else {
            swalNotification.error(
              response.error.cause.cause +
                t(SWAL_MESSAGES.ERROR_CODE) +
                response.error.code +
                response.error.cause.code,
              t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT)
            );
          }
        })
        .catch((e) => {
          swalNotification.error(e, t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT));
        });
    };

    const getAnimalTags = async () => {
      animalTagList.value = [];

      const animalTagListModel: AnimalTagListModel = {
        animalTag: {
          id: 0,
        },
        page: 1,
        pageSize: 50,
      };

      animalTagController
        .animalTagList(animalTagListModel)
        .then((response) => {
          if (response.isSuccess) {
            response.getValue().forEach((animalTag) => {
              animalTagList.value.push(animalTag);
            });
          } else {
            swalNotification.error(
              response.error.cause.cause +
                t(SWAL_MESSAGES.ERROR_CODE) +
                response.error.code +
                response.error.cause.code,
              t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT)
            );
          }
        })
        .catch((e) => {
          swalNotification.error(e, t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT));
        });
    };

    const handleSubmit = () => {
      if (submitButton.value) {
        // Activate indicator
        submitButton.value.setAttribute("data-kt-indicator", "on");
      }

      animalModel.value.acquisitionDate = new Date(acquisitionDate.value);
      animalModel.value.birthDate = new Date(birthDate.value);

      animalController
        .addAnimal(animalModel.value)
        .then((response) => {
          if (response.isSuccess) {
            swalNotification.success(
              t(SWAL_MESSAGES.SUCCESS_CREATE_ANIMAL),
              t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT)
            );
            router.push({ path: `/barnyard/${animalModel.value.barnyard}/animals` });
          } else {
            swalNotification.error(
              response.error.cause.cause +
                t(SWAL_MESSAGES.ERROR_CODE) +
                response.error.code +
                response.error.cause.code,
              t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT)
            );
          }
        })
        .catch((e) => {
          swalNotification.error(e, t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT));
        });

      //Deactivate indicator
      submitButton.value?.removeAttribute("data-kt-indicator");
    };

    const translate = (text) => {
      if (te(text)) {
        return t(text);
      } else return text;
    };

    const removeImage = () => {
      animalModel.value.profilePhotoUrl = "/media/avatars/blank.png";
    };

    const onFileChange = (event) => {
      const file = event.target.files[0];
      const reader = new FileReader();

      reader.onload = (e) => {
        if (e.target && e.target.result) {
          animalModel.value.profilePhotoUrl = e.target.result.toString();
          const imageFile = new File([file], "profilPhoto.jpg", {
            type: file.type,
          });

          animalModel.value.profilePhoto = imageFile;
        }
      };

      reader.readAsDataURL(file);
    };

    return {
      acquisitionDate,
      birthDate,
      PAGE_TITLE_PATH,
      createAnimal,
      barnyardList,
      animalTagList,
      breedList,
      translate,
      animalModel,
      handleSubmit,
      GenderEnum,
      AcquisitionReasonEnum,
      AnimalStatusEnum,
      removeImage,
      onFileChange,
    };
  },
});
</script>
