
import { defineComponent, onMounted, ref } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import { useI18n } from "vue-i18n";
import {
  COMPONENT_NAME,
  PAGE_TITLE_PATH,
  SWAL_MESSAGES,
  VALIDATOR_ERROR,
} from "@/domain/Constant";
import * as Yup from "yup";
import { setCurrentPageTitle } from "@/presentation/helper/Breadcrumb";
import store from "@/presentation/store";
import SwalNotification from "@/presentation/plugins/SwalNotification";
import { GenderEnum } from "@/domain/enum/Gender";
import { AcquisitionReasonEnum } from "@/domain/enum/AcquisitionReason";
import { AnimalStatusEnum } from "@/domain/enum/AnimalStatus";
import { AnimalDetailModel } from "@/domain/animal/animalDetail/model/AnimalDetailModel";
import { BreedListModel } from "@/domain/breed/breedList/model/BreedListModel";
import { AnimalTagListModel } from "@/domain/animalTag/animalTagList/model/AnimalTagListModel";
import { useRouter } from "vue-router";
import { BarnyardListModel } from "@/domain/barnyard/barnyardList/model/BarnyardListModel";


export default defineComponent({
  name: COMPONENT_NAME.CREATE_ANIMAL,
  components: { Field, Form, ErrorMessage },
  methods: {
    checkSelectedOptions(selectedOptions) {
      if (selectedOptions && selectedOptions.length > 3) {
        if (this.animalModel.animalTags)
          this.animalModel.animalTags = this.animalModel.animalTags.slice(0, 3);
      }
    },
  },
  setup() {
    const { t, te } = useI18n();
    const swalNotification = new SwalNotification();
    const scrollElRef = ref<null | HTMLElement>(null);
      const router = useRouter();
    const submitButton = ref<HTMLElement | null>(null);
    const animalTagList = ref<AnimalTagListModel[]>([]);

    const animalTagController =
      store.state.ControllersModule.animalTagController;
    const animalController = store.state.ControllersModule.animalController;
    const breedController = store.state.ControllersModule.breedController;
    const barnyardController = store.state.ControllersModule.barnyardController;
    const barnyardList = ref<any[]>([]);
    const breedList = ref<any[]>([]);
    const acquisitionDate = ref(new Date().toISOString().split("T")[0]);
    const birthDate = ref(new Date().toISOString().split("T")[0]);

    const createAnimal = Yup.object().shape({
      breed: Yup.string()
        .typeError(t(VALIDATOR_ERROR.typeError))
        .test(
          "isNotZero",
          t(VALIDATOR_ERROR.required),
          (value) => value !== "0"
        )
        .label("breed"),
      earTag: Yup.string()
        .typeError(t(VALIDATOR_ERROR.typeError))
        .required(t(VALIDATOR_ERROR.required))
        .label("earTag"),
      name: Yup.string()
        .typeError(t(VALIDATOR_ERROR.typeError))
        .required(t(VALIDATOR_ERROR.required))
        .label("name"),
      gender: Yup.string()
        .typeError(t(VALIDATOR_ERROR.typeError))
        .test(
          "isNotZero",
          t(VALIDATOR_ERROR.required),
          (value) => value !== "0"
        )
        .label("gender"),
      acquisitionReason: Yup.string()
        .typeError(t(VALIDATOR_ERROR.typeError))
        .test(
          "isNotZero",
          t(VALIDATOR_ERROR.required),
          (value) => value !== "0"
        )
        .label("acquisitionReason"),
      barnyard: Yup.string()
        .typeError(t(VALIDATOR_ERROR.typeError))
        .test(
          "isNotZero",
          t(VALIDATOR_ERROR.required),
          (value) => value !== "0"
        )
        .label("barnyard"),
      liveWeight: Yup.number()
        .typeError(t(VALIDATOR_ERROR.typeError))
        .min(0, t(VALIDATOR_ERROR.zeroValue))
        .label("liveWeight"),
      acquisitionPrice: Yup.number()
        .typeError(t(VALIDATOR_ERROR.typeError))
        .min(0, t(VALIDATOR_ERROR.zeroValue))
        .label("acquisitionPrice"),
    });

    const barnyardId = ref(Number(store.getters.selectedBarnyardId));

    const animalModel = ref<AnimalDetailModel>({
      id: 0,
      earTag: "",
      motherEarTag: "",
      fatherEarTag: "",
      name: "",
      animalBreedId: 0,
      barnyard: barnyardId.value,
      gender: "0",
      acquisitionDate: new Date(),
      acquisitionPrice: 0,
      acquisitionReason: "0",
      liveWeight: 0,
      status: "0",
      profilePhotoUrl: "media/animal/animal.jpg",
      birthDate: new Date(),
    });

    const currentPage = ref(1);
    const pageSize = ref(10);
    const totalItems = ref(0);
    const pageCount = ref(1);

    onMounted(() => {
      setCurrentPageTitle(t(PAGE_TITLE_PATH.ANIMAL_CREATE));
      if (scrollElRef.value) {
        scrollElRef.value.scrollTop = 0;
      }

      getBarnyardList();

      if (store.getters.selectedBarnyardId) {
        animalModel.value.barnyard = store.getters.selectedBarnyardId;
      }

      getBreeds();
      getAnimalTags();
    });

    const getBarnyardList = () => {

      const barnyardListModel: BarnyardListModel = {
        barnyard: {},
        page: 1,
        pageSize: 50,
      };

      barnyardController
        .barnyardList(barnyardListModel)
        .then((response) => {
          if (response.isSuccess) {
            response.getValue().forEach((barnyard) => {
              totalItems.value = barnyard.pagination.total;
              currentPage.value = barnyard.pagination.page;
              pageCount.value = barnyard.pagination.pageCount;
              barnyardList.value.push(barnyard);
            });
          } else {
            swalNotification.error(
              response.error.cause.cause +
                t(SWAL_MESSAGES.ERROR_CODE) +
                response.error.code +
                response.error.cause.code,
              t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT)
            );
          }
        })
        .catch((e) => {
          swalNotification.error(e, t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT));
        });
    };

    const getBreeds = () => {
      const breedListModel: BreedListModel = {
        breed: {
          id: 0,
        },
        page: 1,
        pageSize: 10,
      };

      breedController
        .breedList(breedListModel)
        .then((response) => {
          if (response.isSuccess) {
            response.getValue().forEach((breed) => {
              breedList.value.push(breed);
            });
          } else {
            swalNotification.error(
              response.error.cause.cause +
                t(SWAL_MESSAGES.ERROR_CODE) +
                response.error.code +
                response.error.cause.code,
              t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT)
            );
          }
        })
        .catch((e) => {
          swalNotification.error(e, t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT));
        });
    };

    const getAnimalTags = async () => {
      animalTagList.value = [];

      const animalTagListModel: AnimalTagListModel = {
        animalTag: {
          id: 0,
        },
        page: 1,
        pageSize: 50,
      };

      animalTagController
        .animalTagList(animalTagListModel)
        .then((response) => {
          if (response.isSuccess) {
            response.getValue().forEach((animalTag) => {
              animalTagList.value.push(animalTag);
            });
          } else {
            swalNotification.error(
              response.error.cause.cause +
                t(SWAL_MESSAGES.ERROR_CODE) +
                response.error.code +
                response.error.cause.code,
              t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT)
            );
          }
        })
        .catch((e) => {
          swalNotification.error(e, t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT));
        });
    };

    const handleSubmit = () => {
      if (submitButton.value) {
        // Activate indicator
        submitButton.value.setAttribute("data-kt-indicator", "on");
      }

      animalModel.value.acquisitionDate = new Date(acquisitionDate.value);
      animalModel.value.birthDate = new Date(birthDate.value);

      animalController
        .addAnimal(animalModel.value)
        .then((response) => {
          if (response.isSuccess) {
            swalNotification.success(
              t(SWAL_MESSAGES.SUCCESS_CREATE_ANIMAL),
              t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT)
            );
            router.push({ path: `/barnyard/${animalModel.value.barnyard}/animals` });
          } else {
            swalNotification.error(
              response.error.cause.cause +
                t(SWAL_MESSAGES.ERROR_CODE) +
                response.error.code +
                response.error.cause.code,
              t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT)
            );
          }
        })
        .catch((e) => {
          swalNotification.error(e, t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT));
        });

      //Deactivate indicator
      submitButton.value?.removeAttribute("data-kt-indicator");
    };

    const translate = (text) => {
      if (te(text)) {
        return t(text);
      } else return text;
    };

    const removeImage = () => {
      animalModel.value.profilePhotoUrl = "/media/avatars/blank.png";
    };

    const onFileChange = (event) => {
      const file = event.target.files[0];
      const reader = new FileReader();

      reader.onload = (e) => {
        if (e.target && e.target.result) {
          animalModel.value.profilePhotoUrl = e.target.result.toString();
          const imageFile = new File([file], "profilPhoto.jpg", {
            type: file.type,
          });

          animalModel.value.profilePhoto = imageFile;
        }
      };

      reader.readAsDataURL(file);
    };

    return {
      acquisitionDate,
      birthDate,
      PAGE_TITLE_PATH,
      createAnimal,
      barnyardList,
      animalTagList,
      breedList,
      translate,
      animalModel,
      handleSubmit,
      GenderEnum,
      AcquisitionReasonEnum,
      AnimalStatusEnum,
      removeImage,
      onFileChange,
    };
  },
});
